import React, { Component } from 'react';
import ClickOutHandler from 'react-onclickout';
import axios from 'axios';
import ToWhom from './ToWhom/ToWhom';

class CreateNewPost extends Component {
    state = {
        showModal: false,
        content: '',
        loading: false,
        postLevel: 'ALL',
        photo: '',

    }

    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        })
    }

    changePostLevel = (ACTION) => {
      this.setState({
        postLevel: ACTION
      })
    }

    createPost = () => {
        const data = new FormData();
        if (this.state.photo.uri) {
          data.append("photo", {
            name: 'photo',
            type: this.state.photo.type,
            uri: this.state.photo.uri,
          });  
        }
        
        const body = {
          content: this.state.content,
          postLevel: this.state.postLevel
        }
    
        Object.keys(body).forEach(key => {
          data.append(key, body[key]);
        });
      
        const headers = {
          'x-auth': this.props.token,
          'content-type': 'multipart/form-data'
        }
    
        if (this.state.content) {
          axios.post(`/api/post/new`, data, 
            {
              headers: headers
            }
          ).then((res) => {
            this.toggleLoading();
            this.props.refreshFeed();
            this.setState({
                content: '',
                photo: '',
                showModal: false
            })
          }).catch((err) => {
            alert(err);
            this.toggleLoading();
          })
    
        } else {
          alert('Please add content to your post.')
        }    
    }
    
    render() { 
        const user = this.props.user;
        return (
            <>
                <div className="create-new-post">
                    <img className="avatar" alt="user" src={user.profilePhoto} />
                    <div onClick={() => this.setState({showModal: true})} className="whats-on-mind">
                        What's on your mind{user.firstName ? `, ${user.firstName}` : '' }?
                    </div>
                </div>

                {this.state.showModal && (
                        <div className="modal-backdrop">
                            <ClickOutHandler onClickOut={() => this.setState({ showModal: false })}>

                                <div className="post">
                                    <div className="create-post-header">
                                        <h1>Create Post</h1>
                                        <div className="div-line"></div>
                                    </div>
                                    <div className="post-info">
                                        <img className="avatar" alt="avatar" src={user.profilePhoto} />
                                        <div>
                                            <h1>{user.firstName} {user.lastName}</h1>
                                            <ToWhom whom={this.state.postLevel} changePostLevel={this.changePostLevel} />
                                        </div>
                                    </div>
                                    <div className="new-post-content">
                                        <textarea 
                                            placeholder={`What's on your mind${user.firstName ? `, ${user.firstName}` : '' }?`}
                                            rows="15" 
                                            value={this.state.content} 
                                            onChange={(e) => this.setState({content: e.target.value})}
                                        ></textarea>

                                        {/* {post.photo && (
                                            <div className="photo-reveal">
                                                <img alt="post" src={post.photo} />
                                            </div>
                                        )} */}
                                    </div>

                                    <div className="create-post-header">
                                        {this.state.content ? <button onClick={this.createPost} className="post-btn">{this.state.loading ? 'Loading' : 'Post'}</button> : <button disabled className="post-btn">Post</button>}
                                    </div>
                                </div>
                            </ClickOutHandler>

                        </div>
                )}
            </>
        );
    }
}
 
export default CreateNewPost;