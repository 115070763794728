import React, { Component } from 'react';
import * as moment from 'moment';
import axios from 'axios';
import TextareaAutosize from 'react-autosize-textarea';
import Comment from './Comment';

class Post extends Component {
    state = { liked: false, commentContent: '' }

    componentDidMount() {
        this.likeButton();
    }

    likePost = () => {
        const headers = {
          'x-auth': this.props.token
        };
    
        axios.post(`/api/posts/${this.props.post._id}/like`, {}, {headers: headers} ).then((res) => {
          this.setState({showOptions: false, liked: !this.state.liked}, () => {
              this.props.refreshFeed();
          })
        }).catch((err) => {
          alert('Something went wrong. Maybe you don\'t have permission to do that.');
        })
    
    }
    
    likeButton = () => {
        this.props.post.likes.forEach((like) => {
            if (like) {
                if (like.userId === this.props.user._id) {
                    return this.setState({liked: true})
                }        
            }
        })
    }    

    renderLikep = () => {
        const length = this.props.post.likes.length;
        if (length) {
          switch(length) {
            case 1:
              return <p>by {this.props.post.likes[0].name.split(' ')[0]}</p>
            case 2:
              return <p>by {this.props.post.likes[0].name.split(' ')[0]} and {this.props.post.likes[1].name.split(' ')[0]}</p>
            case 3:
              return <p>by {this.props.post.likes[0].name.split(' ')[0]}, {this.props.post.likes[1].name.split(' ')[0]} and {length - 2} other</p>
            default:
              return <p>by {this.props.post.likes[0].name.split(' ')[0]}, {this.props.post.likes[1].name.split(' ')[0]} and {length - 2} others</p>
          }
        }
    }
    
    postComment = () => {
        const data = {
          content: this.state.commentContent,
        };
    
        const headers = {
          'x-auth': this.props.token
        };
    
        if (this.state.commentContent) {
          axios.post(`/api/posts/${this.props.post._id}/comment/new`, data, 
            {
              headers: headers
            }
          ).then((res) => {
            this.props.refreshFeed();
            this.setState({
                commentContent: ''
            })
          }).catch((err) => {
            alert(err);
          })
        } else {
          alert('Please add content to your post.')
        }
    
    }
    

    render() { 
        const post = this.props.post;
        const user = this.props.user;
        return (
            <div className="post" key={post._id}>
                <div className="post-info">
                    <img className="avatar" alt="avatar" src={post.authorPhoto} />
                    <div>
                        <h1>{post.authorName}</h1>
                        <h3>{moment(post.createdAt).fromNow()}</h3>
                    </div>
                </div>
                <div className="post-content">
                    <p>{post.content}</p>

                    {post.photo && (
                        <div className="photo-reveal">
                            <img alt="post" src={post.photo} />
                        </div>
                    )}
                </div>
                <div className="div-line"></div>

                <div className="post-interaction">
                    <div className="like-container">
                        <button onClick={this.likePost}>{this.state.liked ? `❤️ ${this.props.post.likes.length ? this.props.post.likes.length : 1}` : `🖤 ${this.props.post.likes.length}`}</button>
                        {this.renderLikep()}
                    </div>

                    <p>{post.comments.length} Comment{post.comments.length === 1 ? '' : 's'}</p>
                </div>

                <h5 className="comment-heading">Comments</h5>
                <div className="div-line"></div>

                {post.comments && (
                    post.comments.map((comment) => (
                        <Comment key={comment._id} comment={comment} user={this.props.user} token={this.props.token} refreshFeed={this.props.refreshFeed} />
                    ))
                )}
                <div className="div-line"></div>

                <div className="reply-box">
                    <img className="avatar" alt="user" src={user.profilePhoto} />
                    <TextareaAutosize 
                        style={{ minHeight: 32, maxHeight: 300 }} 
                        placeholder="Write a comment..." 
                        value={this.state.commentContent}
                        onChange={(e) => this.setState({commentContent: e.target.value})}
                    />

                    {this.state.commentContent && (
                        <button onClick={this.postComment} className="comment-btn">Post</button>
                    )}
                </div>

            </div>
        );
    }
}
 
export default Post;