import React, { Component } from 'react';
import axios from 'axios';
import * as moment from 'moment';

class Comment extends Component {

    state = {
        liked: false,
        likeHovered: false
    }

    componentDidMount() {
        this.likeButton();
    }

    likeComment = () => {
        const headers = {
          'x-auth': this.props.token
        };
    
        axios.post(`/api/comments/${this.props.comment._id}/like`, {}, {headers: headers} ).then((res) => {
          this.props.refreshFeed();
          this.setState({liked: !this.state.liked})
        }).catch((err) => {
          alert('Something went wrong. Maybe you don\'t have permission to do that.');
        })
    }
    
    likeButton = () => {
        this.props.comment.likes.forEach((like) => {
            if (like.userId === this.props.user._id) {
            return this.setState({liked: true, likeHovered: false})
            }
        })
    }
    

    renderLikeText = () => {
        const length = this.props.comment.likes.length;
        if (length) {
          switch(length) {
            case 1:
              return <>by {this.props.comment.likes[0].name.split(' ')[0]}</>
            case 2:
              return <>by {this.props.comment.likes[0].name.split(' ')[0]} and {this.props.comment.likes[1].name.split(' ')[0]}</>
            case 3:
              return <>by {this.props.comment.likes[0].name.split(' ')[0]}, {this.props.comment.likes[1].name.split(' ')[0]} and {length - 2} other</>
            default:
              return <>by {this.props.comment.likes[0].name.split(' ')[0]}, {this.props.comment.likes[1].name.split(' ')[0]} and {length - 2} others</>
          }
        }
    }

    toggleLikeHover = () => {
        this.setState({
            likeHovered: !this.state.likeHovered
        })
    }

    render() { 
        const comment = this.props.comment;
        return (
            <div className="comment">
                <div className="comment-row">
                    <img className="avatar" alt="user" src={comment.authorPhoto} />
                    <div className="comment-bubble">
                        <h1>{comment.authorName}</h1>
                        <p>{comment.content}</p>
                    </div>
                </div>
                <p className="comment-time">
                    <button onClick={this.likeComment} className="comment-like-btn">
                        <span onMouseEnter={this.toggleLikeHover} onMouseLeave={this.toggleLikeHover}>{this.state.liked ? `❤️ ${comment.likes.length}` : `🖤 ${comment.likes.length}`}</span>
                    </button>
                    <span className="comment-like-text">{this.renderLikeText()}</span> 

                    <span className="time-span">{moment(comment.createdAt).fromNow()}</span>

                    {(this.state.likeHovered && comment.likes[0]) && (
                        <div className="comment-like-list">
                            <div className="list-label"><strong>Like List</strong></div>
                            {comment.likes.map((like) => (
                                <div key={like._id}>{like.name}</div>
                            ))}
                        </div>
                    )}
                </p>
            </div>
        );
    }
}
 
export default Comment;