import axios from 'axios';
import { FETCH_USER, FETCH_POSTS, FETCH_LODGE } from './types';

export const fetchUser = () => async dispatch => {
  const token = localStorage.getItem('x-auth')
  
  const headers = {
    'x-auth': token
   }

  const res = await axios.get('/api/profile', {headers: headers});
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const fetchLodge = () => async dispatch => {
  const token = localStorage.getItem('x-auth')
  
  const headers = {
    'x-auth': token
   }

  const res = await axios.get('/api/lodge', {headers: headers});
  dispatch({ type: FETCH_LODGE, payload: res.data });
};



export const fetchPosts = () => async dispatch => {
  const res = await axios.get('/api/posts');

  dispatch({ type: FETCH_POSTS, payload: res.data });
};
