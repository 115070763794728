import React, { Component } from 'react';
import axios from 'axios';
import SideBar from '../Layout/SideBar';
import MemberCard from './MemberCard';

class ManageMembers extends Component {

  state = {
    members: [],
    lodgeAdmins: [],
    admin: false
  }

  componentDidMount() {
    const token = localStorage.getItem('x-auth')
  
    const headers = {
      'x-auth': token
     }

    // this.checkAdminRights(headers);
    this.getRoster(headers);

  }

  checkAdminRights = (headers) => {
    let admin = false;
    axios.get('/api/lodge/admins', {headers}).then((res) => {
      let admins = res.data;
      admins.forEach((admin) => {
        if (admin._id === this.props.user._id) {
          admin = true;
        }
      })
      this.setState({lodgeAdmins: admins, admin: admin});
    })
  }

  getRoster = (headers) => {
    axios.get('/api/lodge/roster', {headers}).then((res) => {
      this.setState({members: res.data});
    })
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <div className="dashboard">
        <SideBar user={this.props.user} lodge={this.props.lodge} />

        <div className="new-lodge sidebar-right">
          <div className="feed-center">
            <h1>Manage Members</h1>
            <div className="manage-members">
              {this.state.members.map((member, index) => (
                <MemberCard member={member} index={index} key={member._id} user={this.props.user} token={this.props.token} lodge={this.props.lodge} admins={this.state.admins} admin={this.state.admin} />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ManageMembers;