import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

class Login extends Component {
  state = {
    email: '',
    password: ''
  }

  emailChange = (e) => {
    this.setState({email: e.target.value})
  }

  passwordChange = (e) => {
    this.setState({password: e.target.value})
  }

  login = () => {
    axios.post('/api/login', {email: this.state.email, password: this.state.password}).then((res) => {
      console.log(res);
      let xAuth = res.headers['x-auth'];
      console.log(xAuth)
      localStorage.setItem('x-auth', xAuth);
      window.location = '/feed';
    })
  }

  render() {
    return (
      <div className="landing sign-up">
        <img alt="lodge-app" height="300" src="https://i.ibb.co/Dpfxwgb/logo.png" />
        <div className="landing-content-box centered column">
            <h3>Login</h3>
            <label>Email</label>
            <input placeholder="hiram@gmail.com" value={this.state.email} onChange={(e) => this.emailChange(e)} />
            <label>Password</label>
            <input type="password" value={this.state.password} onChange={(e) => this.passwordChange(e)} />
            <button onClick={() => this.login()} className="login-button">Log In</button>
            <br />
            <Link to="/register">Or register</Link>
          </div>
      </div>
    )
  }
}

export default Login;