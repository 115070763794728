import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

class Register extends Component {
  state = {
    email: '',
    password: '',
    confirmPassword: '',
    error: ''
  }

  componentDidMount() {
  }

  emailChange = (e) => {
    this.setState({email: e.target.value})
  }

  passwordChange = (e) => {
    this.setState({password: e.target.value})
  }

  confirmPasswordChange = (e) => {
      this.setState({
          confirmPassword: e.target.value
      })
  }

  register = () => {
      if (this.state.password === this.state.confirmPassword) {
        axios.post('/api/register', {email: this.state.email, password: this.state.password}).then((res) => {
            console.log(res)
            if (res.data.error) {
                this.setState({
                    error: res.data.error
                })
            } else {
                // let xAuth = res.headers['x-auth'];
                // localStorage.setItem('x-auth', xAuth);
                window.location = '/login';    
            }
          })      
      } else {
          this.setState({
              error: 'Passwords don\'t match'
          })
      }
  }

  render() {
    return (
      <div className="landing sign-up">
        <img alt="lodge-app" height="300" src="https://i.ibb.co/Dpfxwgb/logo.png" />
        <div className="landing-content-box centered column">
            {this.state.error && <p>{this.state.error}</p>}
          <h3>Register</h3>
          <label>Email</label>
          <input placeholder="hiram@gmail.com" value={this.state.email} onChange={(e) => this.emailChange(e)} />
          <label>Password</label>
          <input type="password" value={this.state.password} onChange={(e) => this.passwordChange(e)} />
          <label>Confirm Password</label>
          <input type="password" value={this.state.confirmPassword} onChange={(e) => this.confirmPasswordChange(e)} />
          <button onClick={() => this.register()} className="login-button">Register</button>

            <br />
          <Link to="/login">Or Login</Link>
        </div>
      </div>
    )
  }
}

export default Register;