import React, { Component } from 'react';
import SideBar from '../Layout/SideBar';
import axios from 'axios';
import File from './File';
import ReactFilestack from 'filestack-react';


class Documents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            uploadingFile: false,
            uploadedFileLevel: '',
            uploadedFile: {},
            uploadPerm: 'all',
            accessLevel: 'all'
        }
    
    }

    componentDidMount() {
        this.getDocuments();
    }

    addFile = (res) => {
        let fileData = res.filesUploaded[0];
        const data = {
            url: fileData.url,
            fileType: fileData.mimetype,
            fileName: fileData.filename
        };

        this.setState({
            uploadingFile: true,
            uploadedFile: data
        })

    }

    uploadFile = () => {
        let fileData = this.state.uploadedFile
        const data = {
            url: fileData.url,
            fileType: fileData.fileType,
            fileName: fileData.fileName,
            accessLevel: this.state.uploadPerm
          };
      
          const headers = {
            'x-auth': this.props.token
          };
      
          if (fileData.url) {
            axios.post(`/api/file/new`, data, 
              {
                headers: headers
              }
            ).then((res) => {
                this.setState({
                    uploadedFile: false,
                    uploadingFile: false,
                    accessLevel: data.accessLevel
                }, () => {
                    this.getDocuments();
                })
            }).catch((err) => {
              alert(err);
            })
          } else {
            alert('There was an error uploading your file.')
          }
  
    }

    changeAccessLevel = (e) => {
        this.setState({
            accessLevel: e.target.value
        }, () => {
            this.getDocuments();
        })
    }

    getDocuments = () => {
        const token = localStorage.getItem('x-auth')
  
        const headers = {
          'x-auth': token
         }
    
        axios.get('/api/documents/' + this.state.accessLevel, {headers}).then((res) => {
            this.setState({files: res.data});
        })
    }
    
    render() { 
        return (
            <div className="dashboard">
            <SideBar user={this.props.user} lodge={this.props.lodge} />
            <div className="sidebar-right dashboard-main">
              <div className="feed-center">

                <div className="space-between">
                    <h1>Documents</h1>
                    <div>
                        Access Level: 
                        <select value={this.state.accessLevel} onChange={(e) => this.changeAccessLevel(e)}>
                            <option value="all">Everyone</option>
                            <option value="EA">EA</option>
                            <option value="FC">FC</option>
                            <option value="MM">MM</option>
                        </select>
                    </div>
                </div>



                <div className="dashboard-container">

                    <ReactFilestack
                        componentDisplayMode={{
                            type: 'button',
                            customText: 'Upload New File',
                            customClass: 'update-btn'
                        }}                      
                        apikey={'AqjkL5PxGTHeHeohpxRqJz'}
                        onSuccess={(res) => this.addFile(res)}
                    />

                    {this.state.uploadingFile && (
                        <>  

                            <h3>Set file permission level</h3>
                            <select value={this.state.uploadPerm} onChange={(e) => {this.setState({uploadPerm: e.target.value})}}>
                                <option value="all">Everyone</option>
                                <option value="EA">EA</option>
                                <option value="FC">FC</option>
                                <option value="MM">MM</option>
                            </select>
                            <br />
                            <button onClick={this.uploadFile} className="update-btn" style={{background: '#0e810e'}}>Save</button>
                        </>
                    )}

                </div>

                <div className="dashboard-container">
                    <h3>Lodge Files</h3>
                    <div className="docs">
                        {this.state.files.map((file) => (
                            <File key={file._id} file={file} />
                        ))}
                    </div>
                </div>


            </div>


          </div>
        </div>

        );
    }
}
 
  
export default Documents;