import React, { Component } from 'react';
import SideBar from './Layout/SideBar';
import axios from 'axios';
import * as actions from '../actions';
import { connect } from 'react-redux';


class UserSettings extends Component {
    state = {
        email: this.props.user.email,
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        phone: this.props.user.phone,
        profilePhoto: this.props.user.profilePhoto,
        updated: false,
        loading: false,
        selectedFile: [],
    }

    logOut = () => {
        localStorage.removeItem('x-auth');
        if (!localStorage.getItem('x-auth')) {
          window.location = "/"
        }
    }

    changePhone = e => {
        let phone = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        phone = !phone[2] ? phone[1] : '(' + phone[1] + ') ' + phone[2] + (phone[3] ? '-' + phone[3] : '');
        this.setState({phone: phone});
    }

    fileChangedHandler = event => {
        console.log(event.target.files);
        this.setState({ 
            selectedFile: event.target.files[0],
            profilePhoto: URL.createObjectURL(event.target.files[0])
        })
    }

    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        })
    }

    updateProfile = () => {

        this.toggleLoading();
    
        const data = new FormData();
        if (this.state.selectedFile) {
            console.log('selected file', this.state.selectedFile)
          data.append("profilePhoto", {
            name: this.state.selectedFile.fileName,
            type: this.state.selectedFile.type,
            uri: this.state.profilePhoto
          });  
        }
        
        const body = {
          email: this.state.email,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          phone: this.state.phone,
        }
    
        Object.keys(body).forEach(key => {
          data.append(key, body[key]);
        });
        console.log(data._parts)
    
        const headers = {
          'x-auth': this.props.token,
          'content-type': 'multipart/form-data'
        }
    
        axios.post(`/api/profile/edit`, data, 
          {
            headers: headers
          }
        ).then((res) => {

            this.setState({
                email: res.data.email,
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                profilePhoto: res.data.profilePhoto ? res.data.profilePhoto : '',
                updated: true,
                loading: false
            }, () => {
                this.props.fetchUser();
                window.setTimeout(() => {
                    this.setState({updated: true})
                }, 2000)
            })
        }).catch((err) => {
          alert(err);
          this.setState({
            loading: false
          })
        })
         
    }
    
    
    render() { 
        return (
            <div className="dashboard">
            <SideBar user={this.props.user} lodge={this.props.lodge} />
            <div className="sidebar-right dashboard-main">
              <div className="feed-center">

                <h1>User Settings</h1>

                <div className="user-settings">
                    <h3>Change User Information</h3>
                    <label>Email</label>
                    <input type="email" placeholder="elon@musk.com" onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} />
                    <label>First Name</label>
                    <input type="text" placeholder="Hiram" onChange={(e) => this.setState({firstName: e.target.value})} value={this.state.firstName} />
                    <label>Last Name</label>
                    <input type="text" placeholder="Abiff" onChange={(e) => this.setState({lastName: e.target.value})} value={this.state.lastName} />
                    <label>Phone</label>
                    <input type="text" placeholder="(925) 963-2019" value={this.state.phone} onChange={this.changePhone} />
                    <div>
                        <label>Photo</label>
                        <br /><img alt="new uploaded" src={this.state.profilePhoto} />
                        <input type="file" onChange={this.fileChangedHandler} text="Change Profile Picture" />
                    </div>
                    <button className="update-btn" onClick={this.updateProfile}>{this.state.loading ? 'Loading' : 'Update'}</button>
                    <button className="logout-btn" onClick={this.logOut}>Log Out</button>
                </div>

                {this.state.updated && <h1>Updated</h1>}

                <div className="user-settings">
                  <h3>Manage Notifications</h3>
                  <p>To manage your notification settings on desktop, click on the bell in the bottom right corner.</p>
                </div>

            </div>


          </div>
        </div>

        );
    }
}
 
function mapStateToProps({ auth }) {
    return { auth };
  }
  
  export default connect(mapStateToProps, actions)(UserSettings);