import React, { Component } from 'react';
import axios from 'axios';

class MemberCard extends Component {

  state = {
    admin: this.props.user.admin,
    editType: false,
    memberType: this.props.member.lodges[0].memberType,
    editAdmin: false,
    adminType: this.props.member.admin
  }
  
  changeType = (e) => {
    this.setState({memberType: e.target.value})
  }

  changeAdmin = (e) => {
    this.setState({adminType: e.target.value})
  }

  updateUserType = () => {
    const token = localStorage.getItem('x-auth')
  
    const headers = {
      'x-auth': token
     }

    axios.post('/api/user/update-type', {memberId: this.props.member._id, memberType: this.state.memberType}, {headers: headers}).then((res) => {
      this.setState({editType: false})
    })
  }

  updateUserAdmin = () => {
    const token = localStorage.getItem('x-auth')
  
    const headers = {
      'x-auth': token
     }

    axios.post('/api/user/update-admin', {memberId: this.props.member._id, adminType: this.state.adminType}, {headers: headers}).then((res) => {
      this.setState({editAdmin: false})
    })
  }

  render() {
    return (
      <div className="contact-container">
      <img
        alt="member"
        className="member-image"
        src={this.props.member.profilePhoto}
      />
      <div className="content">
        <p className="bold">{this.props.member.firstName} {this.props.member.lastName}</p>
        <p>{this.props.member.email}</p>
        <p>{this.props.member.phone}</p>

        {this.state.editType ? (
          <>
            <select value={this.state.memberType} onChange={this.changeType}>
              <option value="EA">EA</option>
              <option value="FC">FC</option>
              <option value="MM">MM</option>
            </select>
            <button onClick={this.updateUserType}>update</button>
            <button onClick={() => this.setState({editType: false, memberType: this.props.member.lodges[0].memberType})}>cancel</button>
          </>
        ) : (
          <p>
            Member Type: {this.state.memberType}
            {this.state.admin && <span onClick={() => this.setState({editType: true})} className="edit-btn">Edit</span>}
          </p>
        )}





        {this.state.editAdmin ? (
          <>
            <select value={this.state.adminType} onChange={this.changeAdmin}>
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
            <button onClick={this.updateUserAdmin}>update</button>
            <button onClick={() => this.setState({editAdmin: false, adminType: this.state.admin})}>cancel</button>
          </>
        ) : (
          <p>
            Admin: {this.state.adminType ? 'True' : 'False'}
            {this.state.admin && <span onClick={() => this.setState({editAdmin: true})} className="edit-btn">Edit</span>}
          </p>
        )}


      </div>
    </div>
)
  }
}

export default MemberCard;