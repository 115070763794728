import React, { Component, Fragment } from 'react';
import Post from './Post';

class PostsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <>
                {this.props.posts.map((post) => (
                    <Fragment key={post._id}>
                        <Post  post={post} user={this.props.user} token={this.props.token} refreshFeed={this.props.refreshFeed} />
                    </Fragment>
                ))}
            </>
        );
    }
}
 
export default PostsContainer;