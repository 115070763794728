import React, { Component } from 'react';
import './styles.scss';
import SideBar from '../Layout/SideBar';
import LodgeRegister from './LodgeRegister';
import InviteMembers from './InviteMembers';
import axios from 'axios';
class Dashboard extends Component {

  state = {
    users: [],
    lodges: [],
  }

  componentDidMount() {
    this.refreshDashboard();
  }


  getLodgeMembers = () => {
    axios.get('/api/users/all').then((res) => {
      let options = [];
      res.data.forEach((user) => {
        options.push({
          value: user, label: user
        })
      })
      this.setState({
        users: options
      })
    })
  }

  getLodges = () => {
    axios.get('/api/lodges/all').then((res) => {
      let options = [];
      res.data.forEach((lodge) => {
        options.push({
          value: lodge, label: lodge
        })
      })
      this.setState({
        lodges: options
      })
    })
  }


  refreshDashboard = () => {
    this.getLodgeMembers();
    this.getLodges();
  }

  render() {
    return (
      <div className="dashboard">
        <SideBar user={this.props.user} lodge={this.props.lodge} />

        <div className="sidebar-right dashboard-main">
          <div className="feed-center">

            <h1>Dashboard</h1>

            {(!this.props.user.admin && !this.props.user.lodges) && (
              <>
                <h1>Please wait for an admin to review your request and add you to a lodge.</h1>
              </>
            )}

            <div className="modules">
              {this.props.user.admin && (
                <>
                  <LodgeRegister refreshDashboard={this.refreshDashboard} user={this.props.user} lodge={this.props.lodge} users={this.state.users} lodges={this.state.lodges} />
                  <InviteMembers refreshDashboard={this.refreshDashboard} user={this.props.user} lodge={this.props.lodge} users={this.state.users} lodges={this.state.lodges} />
                </>
              )}
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard;