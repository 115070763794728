import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../actions';

import './transitions.css';
import './styles.scss';

import Landing from './Landing';
import Login from './Login';
import Dashboard from './Dashboard/Dashboard';
import LodgeRegister from './Dashboard/LodgeRegister';
import InviteMembers from './Dashboard/InviteMembers';
import ManageMembers from './Dashboard/ManageMembers';
import Register from './Register';
import Feed from './Feed/Feed';
import UserSettings from './UserSettings';
import Documents from './Dashboard/Documents';
import Privacy from './Privacy';

class App extends Component {
  state = {
    token: localStorage.getItem('x-auth'),
    user: {},
    loading: true
  }

  componentDidMount() {
    this.props.fetchUser();
    this.props.fetchLodge();
  }


  render() {
    return (
        <BrowserRouter>
          <div>

            <Route exact path="/"
              component={() => <Landing user={this.props.auth} lodge={this.props.lodge} token={this.state.token} />}
            /> 


            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />

            <Route exact path="/user-settings"
              component={() => <UserSettings user={this.props.auth} lodge={this.props.lodge} token={this.state.token} />}
            /> 

            <Route exact path="/feed"
              component={() => <Feed user={this.props.auth} lodge={this.props.lodge} token={this.state.token} />}
            /> 

            <Route exact path="/dashboard"
              component={() => <Dashboard user={this.props.auth} lodge={this.props.lodge} token={this.state.token} />}
            /> 

            <Route exact path="/documents" 
              component={() => <Documents user={this.props.auth} lodge={this.props.lodge} token={this.state.token} />}
            />  

            <Route exact path="/register-lodge"
              component={() => <LodgeRegister user={this.props.auth} lodge={this.props.lodge} token={this.state.token} />}
            />

            <Route exact path="/invite-members"
              component={() => <InviteMembers user={this.props.auth} lodge={this.props.lodge} token={this.state.token} />}
            />

            <Route exact path="/manage-members"
              component={() => <ManageMembers user={this.props.auth} lodge={this.props.lodge} token={this.state.token} />}
            />


            <Route exact path="/privacy" component={Privacy} />

          </div>
        </BrowserRouter>
    );
  };
};


function mapStateToProps({ auth, lodge }) {
  return { auth, lodge };
}

export default connect(mapStateToProps, actions)(App);