import React, { Component, Fragment } from 'react';

class File extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false
        }
    }

    renderFile = () => {
        const file = this.props.file;

        if (file.file.fileType.includes('image')) {
            return (
                <div className="rendered-file">
                    <img alt={file.file.fileName} src={file.file.url} />
                </div>
            )
        } else {
            return (
                <div className="rendered-file">
                    <embed width="100%" height="600" src={file.file.url} />
                </div>
            )    
        }

    }

    render() { 
        const file = this.props.file;
        return (
            <Fragment key={file.url}>
            <div onClick={() => {this.setState({selected: !this.state.selected})}}>
                <div className="lodge-file">
                    <p>Title: {file.file.fileName}</p>
                    <p>Author: {file.user.firstName} {file.user.lastName}</p>
                </div>

            </div>

            {this.state.selected && (
                <>
                    {this.renderFile()}
                </>
            )}

            </Fragment>
        );
    }
}
 
export default File;