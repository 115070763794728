import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Landing extends Component {
  componentDidMount() {
    if (this.props.user._id) {
      window.location = '/feed';
    }
  }
  render() {
    return (
      <>
        <div className="landing">

          <div className="landing-section bkg">
            <div className="landing-content-box">
              <h1>Lodge App</h1>
              <h2>Freemasonry never changes, but the way people communicate does. Bring your lodge up to the 21st century with a secure and private social feed.</h2>

              <h1>How it works</h1>
              <p>1. Create a lodge</p>
              <p>2. Invite your fellow lodge members</p>
              <p>3. Stay uo to date with your lodge</p>

              <h1>Features?</h1>
              <p>- Share and create posts with images, videos, and embedded links</p>
              <p>- Recieve notifcations for important events.</p>
              <p>- Web, Android and iOS apps so all members can stay connected.</p>
              <p>- Filter posts by degree level</p>
              <p>- Create custom groups and invite members from outside your lodge.</p>

            </div>

          </div>

          <div className="landing-section">
            <div className="landing-content-box centered">
              <img alt="lodge-app" height="300" src="https://i.ibb.co/Dpfxwgb/logo.png" />
              <h1>Lodge App</h1>
              <p>Returning users: Connect with your lodge</p>
              <Link className="login-button" to="/login">Login</Link>
              <br />
              <p>New users:</p>
              <Link className="login-button register" to="/register">Register</Link>

              <h3>If you'd like to get your lodge on lodge-app, please email us at knock@lodge-app.com</h3>
            </div>
          </div>
        </div>
        <div className="landing-footer">
          <h2>Made by masons, for masons</h2>
        </div>
      </>
    )
  }
}

export default Landing;