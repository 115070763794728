import React, { Component } from 'react';
import ClickOutHandler from 'react-onclickout';

class ToWhom extends Component {
    constructor(props) {
        super(props);
        this.state = { selected: false, postLevel: {level: 'ALL', text: 'All Lodge Members'} }
    }

    changePostLevel = (postLevel) => {
        this.setState({
            selected: false,
            postLevel: postLevel
        }, () => {
            this.props.changePostLevel(this.state.postLevel.level)
        })
    }

    render() { 
        return (
            <div className="to-whom">
                <h3 onClick={() => this.setState({selected: !this.state.selected})} className="to-whom">{this.state.postLevel.text}</h3>

                {this.state.selected && (
                    <ClickOutHandler onClickOut={() => this.setState({ selected: false })}>
                        <div className="selection-menu">
                            <p>Select the level that this post should be shown to:</p>
                            <h3 onClick={() => this.changePostLevel({level: 'ALL', text: 'All Lodge Members'})}>All Lodge Members</h3>
                            <h3 onClick={() => this.changePostLevel({level: 'EA', text: 'Entered Apprentices +'})}>Entered Apprentices +</h3>
                            <h3 onClick={() => this.changePostLevel({level: 'FC', text: 'Fellow Crafts +'})}>Fellow Crafts +</h3>
                            <h3 onClick={() => this.changePostLevel({level: 'MM', text: 'Master Masons'})}>Master Masons</h3>
                        </div>
                    </ClickOutHandler>
                )}
            </div>
        );
    }
}
 
export default ToWhom;