import React, { Component } from 'react';
import axios from 'axios';
import Select from 'react-select';


class LodgeRegister extends Component {

  state = {
    lodge: '',
    member: '',
    error: '',
    created: false
  }


  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      created: false
    });
  }

  selectUser = (selectedOption) => {
    this.setState({
      member: selectedOption.value,
      created: false
    })
  }

  selectLodge = (selectedOption) => {
    this.setState({
      lodge: selectedOption.value,
      created: false
    })
  }


  inviteNewMember = () => {
    const data = {
      member: this.state.member,
      lodge: this.state.lodge
    }
    const token = localStorage.getItem('x-auth')
  
    const headers = {
      'x-auth': token
    }
  
    axios.post('/api/lodge/invite', data, {headers}).then((res) => {
      if (res.data === 'SUCCESS') {
        this.setState({
          created: true,
          error: '',
          lodge: '',
          member: '',      
        }, () => {
          this.props.refreshDashboard();
        })
      } else {
        this.setState({
          error: 'One of the above is incorrect.'
        })
      }
    })
  }

  render() {
    return (


        <div className="invite-box">
          <h1>Invite to lodge</h1>
          <p className="desc">After a user has signed up they need to be added to the lodges they belong to.</p>

          <label>Member email</label>
          <Select className="form-select" value={this.state.member} options={this.props.users} onChange={this.selectUser} />
          <p>{this.state.member}</p>


          <label>Lodge Name</label>
          <Select className="form-select" value={this.state.lodge} options={this.props.lodges} onChange={this.selectLodge} />
          <p>{this.state.lodge}</p>



          <button onClick={this.inviteNewMember}>Invite Lodge Member</button>

          <h1 onClick={() => this.setState({created: false})}>{this.state.created ? 'Success!' : ''}</h1>
          <h1>{this.state.error ? this.state.error : ''}</h1>

        </div>
    )
  }
}

export default LodgeRegister;