import React, { Component } from 'react';
import axios from 'axios';
import Select from 'react-select';


class LodgeRegister extends Component {
  state = {
    created: false,
    name: '',
    description: '',
    lodgeAdmin: '',
    grandLodge: 'California',
    selectedUser: '',
    err: ''
  }


  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  selectUser = (selectedOption) => {
    this.setState({
      lodgeAdmin: selectedOption.value
    })
  }

  createNewLodge = () => {
    const data = {
      name: this.state.name,
      number: this.state.number,
      description: this.state.description,
      grandLodge: this.state.grandLodge,
      lodgeAdmin: this.state.lodgeAdmin
    }
    const token = localStorage.getItem('x-auth')
  
    const headers = {
      'x-auth': token
     }
  
     if (data.name && data.number && data.lodgeAdmin) {
      axios.post('/api/lodge/new', data, {headers}).then((res) => {
        this.setState({
          created: true,
          name: '',
          description: '',
          lodgeAdmin: '',
          selectedUser: '',
          err: ''
        }, () => {
          this.props.refreshDashboard();
        })
      })
  
     } else {
       this.setState({
         err: 'fill out required fields'
       })
     }
  }

  render() {
    return (
      <div className="invite-box">

        <h1>Create a new lodge</h1>

          <label>Lodge Name</label>
          <input name="name" value={this.state.name} placeholder="Logos" onChange={this.handleInputChange}  />

          <label>Lodge Number</label>
          <input name="number" value={this.state.number} placeholder="861" onChange={this.handleInputChange}  />

          <label>Lodge Description</label>
          <input name="description" value={this.state.description} placeholder="San Francisco" onChange={this.handleInputChange}  />

          <label>Lodge Administrator (WM Email)</label>
          {/* <input name="lodgeAdmin" value={this.state.lodgeAdmin} placeholder="email@email.com" onChange={this.handleInputChange}  /> */}
          <Select className="form-select" value={this.state.lodgeAdmin} options={this.props.users} onChange={this.selectUser} />
          <p>{this.state.lodgeAdmin}</p>

          <label>Grand Lodge</label>
          <input name="grandLodge" value={this.state.grandLodge} placeholder="california" onChange={this.handleInputChange}  />

          {this.state.err && <>{this.state.err}</>}

        <button onClick={this.createNewLodge}>Create</button>

        <h1 onClick={() => this.setState({
          created: false
        })}>{this.state.created ? 'Success!' : ''}</h1>

        </div>
    )
  }
}

export default LodgeRegister;