import React, { Component } from 'react';
import SideBar from '../Layout/SideBar';
import PostsContainer from './PostsContainer';
import CreateNewPost from './CreateNewPost';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './styles.scss';

class Feed extends Component {
    state = {
        posts: [],
        token: ''
    }

    componentDidMount() {
        this.getPosts();          
    }      

    getPosts = () => {
        const token = localStorage.getItem('x-auth')
  
        const headers = {
          'x-auth': token
         }
    
        axios.get('/api/posts', {headers}).then((res) => {
            this.setState({posts: res.data, token: token});
        })
          
    }

    render() { 
        return (
            <div className="dashboard">
                <SideBar user={this.props.user} lodge={this.props.lodge} />
                <div className="sidebar-right dashboard-main">
                    {this.props.user.lodges && this.props.user.lodges[0] ? (
                        <>
                            <div className="feed-center">
                                {!this.props.user.firstName && (
                                    <h3 className="alert-banner"><Link to="/user-settings">Add your name to your profile</Link></h3>
                                )}
                                <CreateNewPost user={this.props.user} token={this.props.token} refreshFeed={this.getPosts} />
                                <PostsContainer posts={this.state.posts} user={this.props.user} lodge={this.props.lodge} token={this.state.token} refreshFeed={this.getPosts} />
                            </div>
                        </>
                    ) : (
                        <>
                            <h3>Please wait for an admin to review your request and add you to a lodge.</h3>
                        </>
                    )}
                </div>
            </div>
        );
    }
}
 
export default Feed;